<template>
  <div class="mt-2">
    <div
      class="content-card-table"
      :class="{ 'content-card-table-resize': returnTableFiltre }"
    >
      <!-- TABLE CONFIGURATION PAR SOCIETE-->
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getAllConfMensuel"
        class="table-setting-config table-setting-config-custom-conf-mensuel"
        :class="{
          'table-setting-config-resize': returnTableFiltre
        }"
        hide-default-footer
        show-expand
        :single-expand="singleExpand"
        :expanded.sync="expanded"
        :calculate-widths="false"
        :fixed-header="true"
        :items-per-page="perPage"
        hide-action
        :no-data-text="
          getConfLoading
            ? 'Chargement en cours ...'
            : `Il n'y a aucun enregistrement à afficher`
        "
      >
        <template v-slot:[`item.data-table-expand`]="{ item }">
          <td class="custom-cell">
            <button class="btn btn-anomalie" @click="getDataForOneRow(item)">
              <font-awesome-icon
                class="icon-plus"
                :icon="['fas', 'square-plus']"
                color="#704ad1"
                title="Afficher Facture(s)"
                v-if="item.showingDetails == false"
              />
              <font-awesome-icon
                class="icon-plus"
                :icon="['fas', 'square-minus']"
                color="#704ad1"
                title="Masquer Facture(s)"
                v-if="item.showingDetails == true"
              />
            </button>
          </td>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <td class="custom-cell">{{ item.vendeur }}</td>
        </template>
        <template v-slot:[`item.vendeur`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.type`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.nom_service_bien`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.reference`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.montant`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.tva`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date_debut`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.date_fin`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.jour_creation`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.frequence`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.description`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <template v-slot:[`item.objet`]="{}">
          <td class="custom-cell">-</td>
        </template>
        <!-- EXPANDED TABLE -->
        <template #expanded-item="{ headers,item }">
          <td
            class="td-expanded"
            :colspan="headers.length"
            v-if="
              item &&
                item.confs &&
                item.confs.length > 0 &&
                item.showingDetails == true
            "
          >
            <table :items="item.confs" class="table-expanded">
              <tbody>
                <tr
                  v-for="(data, index) in item.confs"
                  :key="data + 'config' + index"
                >
                  <!-- VIDE -->
                  <td
                    class="td-expanded"
                    style="width:40px  !important ; min-width:40px !important"
                  >
                    -
                  </td>
                  <!-- SOCIETE -->
                  <td class="td-expanded">
                    {{ data.vendeur }}
                  </td>
                  <!-- CLIENT -->
                  <td class="td-expanded">
                    {{ data.acheteur }}
                  </td>
                  <!--TYPE-->
                  <td class="td-expanded">
                    {{ data.type }}
                  </td>
                  <!--NOM SERVICE BIEN-->
                  <td class="td-expanded">
                    {{ data.nom_service_bien }}
                  </td>
                  <!--REFRENCE-->
                  <td class="td-expanded">
                    {{ data.reference }}
                  </td>
                  <!--MONTANT-->
                  <td class="td-expanded">{{ data.montant }} €</td>
                  <!--TVA-->
                  <td class="td-expanded">{{ data.tva }} %</td>
                  <!--DATE DEBUT-->
                  <td class="td-expanded">
                    {{ data.date_debut | formateDate }}
                  </td>
                  <!--DATE FIN-->
                  <td class="td-expanded">
                    {{ data.date_fin | formateDate }}
                  </td>
                  <!--JOUR CREATION-->
                  <td class="td-expanded">
                    {{ data.jour_creation | formateJourCreation }}
                  </td>
                  <!--FREQUENCE-->
                  <td class="td-expanded">
                    {{ data.frequence }}
                  </td>
                  <!--DESCRIPTION-->
                  <td class="td-expanded">
                    {{ data.description ? data.description : '-' }}
                  </td>
                  <!--OBJET-->
                  <td class="td-expanded">
                    {{ data.objet ? data.objet : '-' }}
                  </td>

                  <td class="td-expanded">
                    <actionConfiguration :item="data" type="client" />
                  </td>
                </tr>
              </tbody>
            </table>
          </td>
        </template>
      </v-data-table>
    </div>
    <div class="footer-style-table">
      <div class="text-center pagination-table">
        <v-pagination
          v-model="page"
          :length="totalPages"
          @input="pagination"
          :total-visible="7"
        ></v-pagination>
      </div>
      <div class="select-input-vuetify">
        <v-select
          v-model="perPage"
          :items="perPageList"
          @change="changePerPage"
          label="Eléments par page"
          color="#704ad1"
          item-color="#704ad1"
          outlined
          dense
          hide-details
          no-data-text="Aucun élément trouvé"
          :menu-props="{
            left: true,
            offsetY: true,
            closeOnClick: true,
            closeOnContentClick: true
          }"
        ></v-select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import moment from 'moment';

export default {
  name: 'Gestion-des-produits',
  data() {
    return {
      singleExpand: false,
      errorDuplicate: null,
      expanded: [],
      selectedTable: [],
      validateToAddconf: false,
      loading: false,
      error: [],
      listTva: [],
      valeur: null,
      errorTvaUnite: null,
      configurationToDelete: null,
      configurationToUpdate: null,
      freqOptions: [{ text: 'Mensuel', value: 'mensuel' }],
      oldConfigurationToUpdate: null,

      fields: [
        {
          value: 'data-table-expand',
          text: '',
          show: true,
          sortable: false,
          width: '40px !important'
        },
        {
          value: 'name',
          text: 'Societé',
          sortable: false
        },
        {
          value: 'vendeur',
          text: 'Client',
          sortable: false
        },
        {
          value: 'type',
          text: 'Type',
          sortable: false
        },
        {
          value: 'nom_service_bien',
          text: 'Nom Bien/Service',
          sortable: false
        },

        {
          value: 'reference',
          text: 'Référence',
          sortable: false
        },
        {
          value: 'montant',
          text: 'Montant',
          sortable: false
        },
        {
          value: 'tva',
          text: 'TVA',
          sortable: false
        },
        {
          value: 'date_debut',
          text: 'Date début',
          sortable: false
        },
        {
          value: 'date_fin',
          text: 'Date fin',
          sortable: false
        },
        {
          value: 'jour_creation',
          text: 'Jour création',
          sortable: false
        },
        {
          value: 'frequence',
          text: 'Fréquence',
          sortable: false
        },

        {
          value: 'description',
          text: 'Description',
          sortable: false
        },
        {
          value: 'objet',
          text: 'Objet',
          sortable: false
        },
        {
          value: 'action',
          text: 'Action',
          sortable: false
        }
      ],
      loadingTvaUnite: false,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      disable: true,
      montant_ttc: 0
    };
  },
  filters: {
    formateDate: value => {
      if (value == '' || value == null) {
        return '-';
      } else if (moment(value, 'DD-MM-YYYY', true).isValid()) {
        return value;
      } else {
        return moment(new Date(value)).format('DD-MM-YYYY');
      }
    },
    formateJourCreation: value => {
      if (value == '' || value == null) {
        return 'Date du jour';
      } else if (value == -1) {
        return 'Fin du mois';
      } else {
        return value;
      }
    }
  },
  methods: {
    ...mapActions([
      'getServices',
      'getAllBiens',
      'addNewConfigurationMensuel',
      'fetchAllMensuel',
      'getSettingFilialeTh',
      'deleteConfComptaRh',
      'updateConfigurationComptaRh',
      'checkPeriodConfig'
    ]),
    getDataForOneRow(item) {
      item.showingDetails = !item.showingDetails;
      if (item.showingDetails == true) {
        if (!this.expanded.includes(item)) {
          this.expanded.push(item);
        }
      }
    },
    calculTTC() {
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
    },
    async handleErrorUpdateConfig() {
      var objToSend = {
        vendeur_id: this.configurationToUpdate.vendeur_id
          ? this.configurationToUpdate.vendeur_id
          : null,
        acheteur_id: this.configurationToUpdate.acheteur_id
          ? this.configurationToUpdate.acheteur_id
          : null,
        date_debut: this.configurationToUpdate.date_debut
          ? this.configurationToUpdate.date_debut
          : null,
        date_fin: this.configurationToUpdate.date_fin
          ? this.configurationToUpdate.date_fin
          : null,
        id: this.configurationToUpdate.id,
        type: this.configurationToUpdate.type,
        service_bien: this.configurationToUpdate.service_bien
      };

      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    async handleErrorDuplicateConfig() {
      var objToSend = {
        vendeur_id: this.configurationToUpdate.vendeur_id
          ? this.configurationToUpdate.vendeur_id
          : null,
        acheteur_id: this.configurationToUpdate.acheteur_id
          ? this.configurationToUpdate.acheteur_id
          : null,
        date_debut: this.configurationToUpdate.date_debut
          ? this.configurationToUpdate.date_debut
          : null,
        date_fin: this.configurationToUpdate.date_fin
          ? this.configurationToUpdate.date_fin
          : null,
        type: this.configurationToUpdate.type,
        service_bien: this.configurationToUpdate.service_bien
      };
      if (objToSend.vendeur_id != null && objToSend.acheteur_id != null) {
        const response = await this.checkPeriodConfig(objToSend);
        this.validateToAddconf = true;
        if (response?.success) {
          if (response.response) {
            this.validateToAddconf = false;
            this.errorDuplicate = null;
          }
        } else {
          this.errorDuplicate = response.error;
          this.validateToAddconf = true;
        }
      }
    },
    resteTvaUniteModal() {
      this.valeur = null;
      this.errorTvaUnite = null;
    },
    disabledEndDateUpdate(date) {
      return (
        this.configurationToUpdate?.date_debut &&
        moment(date, 'YYYY-MM-DD').add(1, 'days') <
          new Date(this.configurationToUpdate?.date_debut)
      );
    },
    disabledStartDateUpdate(date) {
      return (
        this.configurationToUpdate?.date_fin &&
        moment(date, 'YYYY-MM-DD') >=
          new Date(this.configurationToUpdate?.date_fin)
      );
    },
    handleFilter() {
      this.fetchAllMensuel({
        per_page: this.perPage,
        page: this.page,
        grouped: 'vendeur',
        vendeur_ids: this.getFiltre.vendeur,
        acheteur_ids: this.getFiltre.acheteur,
        period: this.getFiltre.period,
        type_produit: this.getFiltre.type_produit,
        service_bien: this.getFiltre.service_bien
      });
    },
    async changePerPage() {
      this.page = 1;
      this.handleFilter();
    },

    async pagination(paginate) {
      this.page = paginate;
      this.handleFilter();
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
    },
    async handleDuplicateConfigComptaRh() {
      this.loading = true;
      const response = await this.addNewConfigurationMensuel({
        configurationToAdd: this.configurationToUpdate,
        type: 'client'
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('duplicateConf');
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    updateCurrentConf(data) {
      this.configurationToUpdate = { ...data };
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
      this.oldConfigurationToUpdate = data;
      this.$refs['updateConfComptaRh'].show();
    },
    duplicateConf(data) {
      this.configurationToUpdate = { ...data };
      let tva =
        Math.round(
          ((parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) *
            parseFloat(
              (this.configurationToUpdate.tva + '').replace(',', '.') || 0
            )) /
            100) *
            100
        ) / 100;
      this.montant_ttc =
        Math.round(
          (parseFloat(
            (this.configurationToUpdate.montant + '').replace(',', '.') || 0
          ) +
            tva) *
            100
        ) / 100;
      this.$refs['duplicateConf'].show();
      this.handleErrorDuplicateConfig();
    },
    async handleUpdateConfigComptaRh() {
      this.loading = true;
      const response = await this.updateConfigurationComptaRh({
        configurationToUpdate: this.configurationToUpdate,
        oldConfigurationToUpdate: this.oldConfigurationToUpdate
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('updateConfComptaRh');
      } else {
        this.loading = false;
        this.error = response.error;
      }
    },
    deleteCurrentConf(data) {
      this.confToDelete = data;
      this.$refs['deleteConfigurationModal'].show();
    },
    async handleDeleteConfComptaRh() {
      this.loading = true;
      const response = await this.deleteConfComptaRh({
        configToDelete: this.confToDelete,
        type: 'client'
      });
      if (response.success) {
        this.loading = false;
        this.hideModal('deleteConfigurationModal');
        if (this.getAllConfMensuel.length === 0 && this.page > 1) {
          let newPage = this.page - 1;
          this.pagination(newPage);
        }
      } else {
        this.error = response.error;
        this.loading = false;
      }
    },
    resetModal() {
      this.loading = false;
      this.error = [];
      this.errorDuplicate = null;
      this.validateToAddconf = false;
    }
  },
  components: {
    actionConfiguration: () => import('./actionConfiguration.vue')
  },
  computed: {
    ...mapGetters([
      'getAllTvas',
      'getSettingFilialesTh',
      'getConfLoading',
      'getConfErrors',
      'getAllConfMensuel',
      'getTotalRowConfComptaRh',
      'checkPermission',
      'biens',
      'services',
      'getFiltre'
    ]),
    returnTableFiltre() {
      return (
        this.getFiltre.vendeur.length ||
        this.getFiltre.acheteur.length ||
        (this.getFiltre.service_bien.length && this.getFiltre.type_produit)
      );
    },
    computedListJourCreation() {
      return [
        { text: 'Jour du création', value: null },
        { text: 'Fin du mois', value: -1 },
        { text: '01', value: 1 },
        { text: '02', value: 2 },
        { text: '03', value: 3 },
        { text: '04', value: 4 },
        { text: '05', value: 5 },
        { text: '06', value: 6 },
        { text: '07', value: 7 },
        { text: '08', value: 8 },
        { text: '09', value: 9 },
        { text: '10', value: 10 },
        { text: '11', value: 11 },
        { text: '12', value: 12 },
        { text: '13', value: 13 },
        { text: '14', value: 14 },
        { text: '15', value: 15 },
        { text: '16', value: 16 },
        { text: '17', value: 17 },
        { text: '18', value: 18 },
        { text: '19', value: 19 },
        { text: '21', value: 21 },
        { text: '22', value: 22 },
        { text: '23', value: 23 },
        { text: '24', value: 24 },
        { text: '25', value: 25 },
        { text: '26', value: 26 },
        { text: '27', value: 27 },
        { text: '28', value: 28 },
        { text: '29', value: 29 },
        { text: '30', value: 30 },
        { text: '31', value: 31 }
      ];
    },
    totalPages() {
      if (this.getTotalRowConfComptaRh) {
        return Math.ceil(this.getTotalRowConfComptaRh / this.perPage);
      }
      return this.getTotalRowConfComptaRh;
    },
    ListType() {
      let list = [];
      if (this.checkPermission('GBHT')) {
        list.push({ value: 'bien', text: 'Bien' });
      }
      if (this.checkPermission('GCM')) {
        list.push({ value: 'service', text: 'Service' });
      }
      return list;
    },
    computedListConfig() {
      let selected = [];
      if (
        this.configurationToAdd?.type == 'service' ||
        this.configurationToUpdate?.type == 'service'
      ) {
        return this.services.map(service => {
          return {
            value: service.id,
            text: service.nom
          };
        });
      } else if (
        this.configurationToAdd?.type == 'bien' ||
        this.configurationToUpdate?.type == 'bien'
      ) {
        return this.biens.map(bien => {
          return {
            value: bien.id,
            text: bien.nom
          };
        });
      } else {
        return selected;
      }
    }
  },
  watch: {
    'getFiltre.vendeur'() {
      this.handleFilter();
    },
    'getFiltre.acheteur'() {
      this.handleFilter();
    },
    'getFiltre.period'() {
      this.handleFilter();
    },
    'getFiltre.type_produit'() {
      this.handleFilter();
    },
    'getFiltre.service_bien'() {
      this.handleFilter();
    },
    deep: true
  },
  async mounted() {
    this.handleFilter();
  }
};
</script>
<style lang="scss" scoped>
.style-box-setting .box-content .content-card-table {
  height: calc(100vh - 298px);
}
.content-card-table-resize {
  height: calc(100vh - 371px) !important;
}
</style>
<style>
.table-setting-config-custom-conf-mensuel .v-data-table__wrapper {
  height: calc(100vh - 301px) !important;
}
</style>
